<template>
  <a-spin :spinning="loading || loadingGet">
    <div class="col-md-12 col-lg-12">
      <div>
        <div class="mb-4 p-0 m-0 col-md-4">
          <a-input-search
            placeholder="Search"
            enter-button="Search"
            size="large"
            v-model="search"
            allow-clear
          />
        </div>

        <p>
          Showing {{ currentPage * pageSize - pageSize + 1 }}-{{
            pageSize * currentPage
          }}
          of {{ totalRecords }} items
        </p>
        <div class="grid-container" v-if="entities.length">
          <tourist-facilities-grid
            v-for="(entity, index) of entities"
            :key="index"
            :entity="entity"
            :entity_type="entity_type"
            :base_url="base_url"
            :data_key="data_key"
            :entity_name="entity_name"
          />
        </div>

        <a-pagination
          v-model="currentPage"
          :pageSizeOptions="pageSizeOptions"
          :pageSize.sync="pageSize"
          :total="totalRecords"
          :show-total="
            (total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} items`
          "
          hideOnSinglePage
          showSizeChanger
          show-less-items
          @change="onPageChange"
          @showSizeChange="onPageSizeChanged"
        />
      </div>

      <div v-if="!entities.length" class="text-center">
        <h4 v-if="!loading">No facilities available for this category.</h4>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { get } from "lodash";
import { QUERY_ENTITIES } from "@/store/actions";

import ProfileMixin from "@/mixins/Profile";
import TouristFacilitiesGrid from "@/components/facilities/TouristFacilitiesGrid.vue";

const watchHandler = {
  handler() {
    this.queryEntities();
  },
  immediate: false,
};

export default {
  mixins: [ProfileMixin],
  components: {
    TouristFacilitiesGrid,
  },
  props: {
    entity_type: {
      type: String,
      required: true,
      default: "",
    },
    base_url: {
      type: String,
      required: true,
      default: "",
    },
    data_key: {
      type: String,
      required: true,
      default: "",
    },
    entity_name: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      pageSize: 10,
      pageSizeOptions: ["10", "50", "100"],
      search: "",
      filterBy: "all",
      currentPage: 1,
      totalRecords: 0,
      entities: [],
    };
  },

  computed: {
    ...mapState("tourist", ["loadingGet"]),
  },
  watch: {
    search: watchHandler,
    entity_type: {
      handler() {
        console.log("---ROOT RUN---");
        const query = this.$route.query;
        const search = get(query, "search");
        const page = 1;
        const limit = +this.pageSizeOptions[0];

        if (search) {
          this.search = search;
        }

        if (page && parseInt(page)) {
          this.currentPage = parseInt(page);
        }

        if (limit && parseInt(limit)) {
          this.pageSize = parseInt(limit);
        }

        this.queryEntities();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("tourist", {
      fetchEntities: QUERY_ENTITIES,
    }),
    onPageChange(page, pageSize) {
      this.currentPage = page;
      this.pageSize = pageSize;
      this.queryEntities();
    },
    onPageSizeChanged(current, size) {
      this.pageSize = size;
      this.currentPage = 1;
      this.queryEntities();
    },
    async queryEntities() {
      try {
        this.loading = true;

        const filterQuery = {
          entity_type: this.entity_type,
          page: this.currentPage,
          limit: this.pageSize,
          search: this.search,
        };

        const response = await this.fetchEntities(filterQuery);
        const { data, pagination } = response;

        this.entities = data || [];
        this.totalRecords = pagination.totalRecords || 0;

        delete filterQuery.entity_type;
        this.$router.replace({ query: filterQuery }).catch((error) => {});
      } catch (error) {
        this.$notification.error({
          message: `An error occurred. Please try again.`,
        });
        console.log("Error ", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.grid-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: auto;
}
</style>
