<template>
  <div>
    <facility-details-card
      class="entity-card"
      :image="getCoveredImage(entity.media)"
      :title="getValueFromSource(entity, 'information.name', 'N/A')"
      :rating="getValueFromSource(entity, 'ratings.total_avg', 0)"
      :entity_type="entity_type"
      :description="
        getValueFromSource(entity, 'information.description', 'N/A')
      "
      :detailLink="`/tourist/${base_url}/${entity._id}`"
      :region="
        getValueFromSource(entity, 'information.address.state.name', 'N/A')
      "
    />
  </div>
</template>

<script>
import { get } from "lodash";
import ProfileMixin from "@/mixins/Profile";

import FacilityDetailsCard from "@/components/facilities/FacilityDetailsCard.vue";

export default {
  mixins: [ProfileMixin],
  components: {
    FacilityDetailsCard,
  },
  props: {
    entity: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    entity_type: {
      type: String,
      required: true,
      default: "",
    },
    base_url: {
      type: String,
      required: true,
      default: "",
    },
    entity_name: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    getValueFromSource(source, path, defaultValue = "") {
      return get(source, path, defaultValue);
    },
    getCoveredImage(media) {
      const covered_image = get(media, "cover.path");
      return covered_image;
      // const featured_1 = get(media, "featured_1.path");
      // const featured_2 = get(media, "featured_2.path");
      // const featured_3 = get(media, "featured_3.path");

      // if (featured_1) {
      //   return featured_1;
      // }
      // if (featured_2) {
      //   return featured_2;
      // }
      // if (featured_3) {
      //   return featured_3;
      // }
      //TODO: specify default image to use, we have to use something
    },
  },
};
</script>
<style scoped>
.entity-card {
  margin-right: 25px;
}
</style>
